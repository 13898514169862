import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export default function PostWithImgComponent({ data, children }) {
  return (
    <a href={data.link} target="_blank">
      <div className="flex flex-col h-full">
        <div className="flex space-x-3">
          <GatsbyImage
            className="w-full h-full object-center object-cover sm:w-full sm:h-full rounded-t-lg"
            image={getImage(data.image.asset)}
            alt={data.name}
          />
        </div>
        <div className="px-4 py-6 sm:p-6 mt-auto">
          <h2
            id={"data-title-" + data.id}
            className="text-sm font-medium text-gray-900"
          >
            {data.name}
          </h2>
          {children}
        </div>
      </div>
    </a>
  );
}

export function PostWithImgDesc({ data }) {
  return (
    <div
      className="mt-2 text-sm text-gray-700 space-y-4"
      dangerouslySetInnerHTML={{ __html: data }}
    />
  );
}
