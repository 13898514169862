import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PostWithImgComponent from "../components/ui/PostWithImgComponent";
import { TitleComponent } from "../components/ui/TitleComponent";
import MarginComponent from "../components/ui/MarginComponent";

export default function IndexPage({ data, location }) {
  return (
    <Layout
      location={location.pathname}
      title="Наши проекты"
      description="Здесь вы можете ознакомиться с нашими проектами по разработке сайтов"
    >
      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Наши проекты</TitleComponent>
        <ul
          role="list"
          className="mt-2 mb-2 grid grid-cols-1 gap-5 sm:grid-cols-1"
        >
          {data.allSanityProjects.nodes.map((project) => (
            <li key={project.id} className="pt-10 text-center">
              <PostWithImgComponent data={project} />
            </li>
          ))}
        </ul>
      </MarginComponent>
    </Layout>
  );
}

export const query = graphql`
  query {
    allSanityProjects(filter: {}, sort: { fields: _createdAt, order: ASC }) {
      nodes {
        name
        id
        link
        image {
          asset {
            id
            gatsbyImageData(width: 1000)
          }
        }
      }
    }
  }
`;
